<template>
  <!-- header start -->
  <header class="scroll_header_top_area dark fixed scrolled_not_transparent page_header ">

    <div class="header_inner clearfix">

      <div class="header_top_bottom_holder">
        <div class="header_bottom clearfix" style=" background-color:rgba(255, 255, 255, 1);">
          <div class="header_inner_left">
            <div class="common-link dropdown d-block d-lg-none airbnb-mob mr_20">
              <a class="dropdown-toggle user-profile no-underline" href="#" id="navbarDropdownMenuLink" role="button"
                data-bs-toggle="dropdown" aria-expanded="false" @click="changeVal()">
                <img :src="(avatar) ? avatar : '../assets/images/home-essentials/user-image.png'" alt="icon"
                  class="img-fluid" />
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">

                <li>
                  <router-link :to="{ name: 'profile' }" class="dropdown-item" @click="manageTogglerCross">Profile
                  </router-link>
                </li>
                <li v-show="adminpermission">
                  <router-link :to="{ name: 'admin_center' }" class="dropdown-item" @click="manageTogglerCross">Admin Dashboard</router-link>
                </li>
                <li v-show="employeepermission">
                  <router-link :to="{ name: employeeroute }" class="dropdown-item" @click="manageTogglerCross">{{ employeeTitle }}</router-link>
                </li>
                <li @click="checkDomain" v-if="switchClient">
                  <a class="dropdown-item">Switch Client</a>
                </li>
                <li @click="manageTogglerCross"><a @click="logout" class="dropdown-item">Logout</a></li>
              </ul>
            </div>
            <div class="mobile_menu_button">
              <button id="manageTogglerCross" type="button" class="navbar-toggler minus collapsed"
                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" :aria-expanded="false" aria-label="Toggle navigation"
                v-on:click="checkMenu">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>

            </div>
            <div class="logo_wrapper" style="height: 90px;">

              <div class="q_logo 656">
                <router-link :to="{ name: 'airbnb' }" itemprop="url" style="height: 80px; visibility: visible;">
                  <img class="normal" src="../assets/images/airbnb/airbnb_logo.png" alt="Logo" style="height: 100%;">
                  <img itemprop="image" class="light" src="../assets/images/airbnb/airbnb_logo.png" alt="Logo"
                    style="height: 100%;">
                  <img itemprop="image" class="dark" src="../assets/images/airbnb/airbnb_logo.png" alt="Logo"
                    style="height: 100%;">
                  <img itemprop="image" class="sticky" src="../assets/images/airbnb/airbnb_logo.png" alt="Logo"
                    style="height: 100%;">
                  <img itemprop="image" class="mobile" src="../assets/images/airbnb/airbnb_logo.png" alt="Logo"
                    style="height: 100%;">
                </router-link>
              </div>
            </div>
          </div>



          <nav class="main_menu drop_down right">
            <ul id="app_menu" class="">
              <li id="nav-menu-item-70445"
                class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-70341 narrow"
                v-show="ergopermission">
                <router-link :to="{ name: 'airbnb' }" class="current nomatter" style="line-height: 90px;">
                  <span>Home</span><span class="plus"></span>
                </router-link>
              </li>
              <li id="nav-menu-item-70463" class="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                v-show="ergopermission">
                <router-link :to="{ name: 'productivity-a45' }" class=" current " style="line-height: 90px;">
                  <span>Productivity</span><span class="plus"></span>
                </router-link>
              </li>
              <li id="nav-menu-item-71297" class="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                v-show="ergopermission">
                <router-link :to="{ name: 'equipment-guides-a45' }" class=" current " style="line-height: 90px;">
                  <span>Equipment Guides</span><span class="plus"></span>
                </router-link>
              </li>

              <li id="nav-menu-item-71347"
                class="button menu-item menu-item-type-post_type menu-item-object-page  narrow" v-show="ergopermission && show_self_assessment_block">
                <router-link :to="{ name: 'get-an-assessment-a45' }" class=" current " style="line-height: 90px;">
                  <span>Get an Assessment</span><span class="plus"></span>
                </router-link>
              </li>
              <li class="nav-item dropdown d-none d-lg-inline-block user"
                v-if="$route.meta.is_header == 'airbnb' || $route.meta.is_header == 'admin' || $route.meta.is_header == 'user_dashboard'">
                <a class="dropdown-toggle user-profile no-underline" href="#" id="navbarDropdownMenuLink" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">

                  <img :src="(avatar) ? avatar : require('@/assets/images/home-essentials/user-image.png')" alt="icon"
                    class="img-fluid" />
                  <!-- <img src="https://staging.pbergo.com/blogs/avatars/avatar_1649841562.png" class="img-fluid"> -->
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <router-link :to="{ name: 'profile' }" class="dropdown-item">Profile</router-link>
                  </li>

                  <li v-show="adminpermission">
                    <router-link :to="{ name: 'admin_center' }" class="dropdown-item">Admin Dashboard</router-link>
                  </li>
                  <li v-show="employeepermission">
                    <router-link :to="{ name: employeeroute }" class="dropdown-item">{{ employeeTitle }}</router-link>
                    <!-- <router-link :to="{ name: 'my_reports' }" class="dropdown-item">Employee Dashboard</router-link> -->
                  </li>
                  <li @click="checkDomain" v-if="switchClient">
                    <a class="dropdown-item">Switch Client</a>
                  </li>
                  <li><a @click="logout" class="dropdown-item">Logout</a></li>
                </ul>
              </li>
            </ul>
          </nav>
          <nav class="mobile_menu navbar d-lg-none">

            <ul class="collapse navbar-collapse" id="navbarSupportedContent">
              <li
                class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-70341 narrow"
                @click="closemob" v-show="ergopermission">
                <router-link :to="{ name: 'airbnb' }">
                  <span>Home</span><span class="plus"></span>
                </router-link>
              </li>
              <li id="nav-menu-item-70463" class="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                @click="closemob" v-show="ergopermission">
                <router-link :to="{ name: 'productivity-a45' }" class=" current ">
                  <span>Productivity</span><span class="plus"></span>
                </router-link>
              </li>
              <li id="nav-menu-item-71297" class="menu-item menu-item-type-post_type menu-item-object-page  narrow"
                @click="closemob" v-show="ergopermission">
                <router-link :to="{ name: 'equipment-guides-a45' }" class=" current ">
                  <span>Equipment Guides</span><span class="plus"></span>
                </router-link>
              </li>
              <li id="nav-menu-item-71347"
                class="button menu-item menu-item-type-post_type menu-item-object-page  narrow mob-top"
                @click="closemob" v-show="ergopermission">
                <router-link :to="{ name: 'get-an-assessment-a45' }" class=" current ">
                  <span>Get an Assessment</span><span class="plus"></span>
                </router-link>
              </li>


            </ul>

          </nav>
        </div>
      </div>
    </div>
    <clientPopup v-show="isModalVisible" @close="removeUser" :all-clients="this.clientsData"
      :sso-status="this.sso_status" @closeModal="isModalVisible = $event" />
      <!-- Region popup if not exist in user -->
    <regionPopup v-show="isRegionModalVisible" :all-regions="this.regionsData" @closeModal="isRegionModalVisible = $event" />
  </header>

  <!-- header end -->
</template>

<script>
import {
  getError
} from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import clientPopup from "../components/Clientspopup.vue";
import regionPopup from "../components/Regionpopup.vue";
import commonFunction from '../mixin/commonFunction';

export default {
  name: "HeaderAirbnb",
  mixins: [commonFunction],
  components: {
    clientPopup,
    regionPopup,
  },
  data() {
    return {
      company_logo: require('@/assets/images/logo.png'),
      showHideToggler: true,
      divscrolltop: 0,
      open: false,
      avatar: require('@/assets/images/home-essentials/user-image.png'),
      adminpermission: false,
      employeepermission: false,
      videopermission: false,
      ergopermission: false,
      selfassessmentpermission: false,
      employeeroute: 'my_reports',
      employeeTitle: "Employee Dashboard",
      program_overview_permission: false,
      self_assessment_permission: false,
      ergo_eval_activity_permission: false,
      follow_up_feedback_permission: false,
      employee_search_permission: false,
      evaluator_permission: false,
      client_activity_permission: false,
      client_manager_permission: false,
      switchClient: false,
      clientsData: [],
      regionsData:[],
      sso_status: false,
      isModalVisible: false,
      isRegionModalVisible: false,
      show_self_assessment_block: true,
    };
  },
  beforeMount() {
    // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res.then(value => {
        let allPermission = value.user.roles.all_permission;
        if(allPermission.includes("switch-client")){
          if (value.user.admin_sso_status == 0) {
            this.switchClient = true;
          } else {
            this.switchClient = false;
          }
        }
        this.ergopermission = allPermission.includes('site-ergo-ally-site-view');
        this.adminpermission = allPermission.includes('site-access-admin-center-view');
        this.videopermission = allPermission.includes('site-video-page-only-view');
        this.program_overview_permission = allPermission.includes(
          "dashboard-pages-program-overview-view"
        );
        this.self_assessment_permission = allPermission.includes(
          "dashboard-pages-self-assessment-view"
        );
        this.ergo_eval_activity_permission = allPermission.includes(
          "dashboard-pages-ergo-eval-activity-view"
        );
        this.follow_up_feedback_permission = allPermission.includes(
          "dashboard-pages-follow-up-feedback-view"
        );
        this.employee_search_permission = allPermission.includes(
          "dashboard-pages-employee-search-view"
        );
        // this.evaluator_permission = allPermission.includes('dashboard-pages-evaluator');

        let evaluatorSubPermissions = [
          "evaluator-client-list",
          "evaluator-start-new-evaluation",
          "evaluator-reports-in-review",
        ];
        this.evaluator_permission = allPermission.some((item) =>
          evaluatorSubPermissions.includes(item)
        );

        this.client_activity_permission = allPermission.includes(
          "dashboard-pages-client-activity-view"
        );
        this.client_manager_permission = allPermission.includes(
          "dashboard-pages-client-managers-view"
        );
        let empPermissionArray = [
          "dashboard-pages-employee-dashboard-view",
          "dashboard-pages-employee-help-center-view",
          "dashboard-pages-program-overview-view",
          "dashboard-pages-self-assessment-view",
          "dashboard-pages-ergo-eval-activity-view",
          "dashboard-pages-follow-up-feedback-view",
          "dashboard-pages-employee-search-view",
          "evaluator-client-list",
          "evaluator-reports-in-review",
          "dashboard-pages-client-activity-view",
          "dashboard-pages-client-managers-view",
        ];
        let result = empPermissionArray.some((i) => allPermission.includes(i));
        if (result) {
          this.employeepermission = true;
          if (
            allPermission.includes("dashboard-pages-employee-dashboard-view")
          ) {
            this.employeeroute = "my_reports";
            this.employeeTitle = "Employee Dashboard";
          } else if (this.program_overview_permission) {
            this.employeeroute = "program_overview";
            this.employeeTitle = "Program Overview";
          } else if (this.self_assessment_permission) {
            this.employeeroute = "self_assessment_activity";
            this.employeeTitle = "Self-Assessment";
          } else if (this.ergo_eval_activity_permission) {
            this.employeeroute = "ergo_eval_activity";
            this.employeeTitle = "Ergo Eval Activity";
          } else if (this.follow_up_feedback_permission) {
            this.employeeroute = "follow_up_feedback";
            this.employeeTitle = "Follow-Up Feedback";
          } else if (this.employee_search_permission) {
            this.employeeroute = "employee_search";
            this.employeeTitle = "Employee Search";
          } else if (this.evaluator_permission) {
            this.employeeroute = "evaluator";
            this.employeeTitle = "Evaluator";
          } else if (this.client_activity_permission) {
            this.employeeroute = "client_activity";
            this.employeeTitle = "Client Activity";
          } else if (this.client_manager_permission) {
            this.employeeroute = "client_manager";
            this.employeeTitle = "Program Management";
          } else {
            this.employeeroute = "help_center";
            this.employeeTitle = "Employee Help Center";
          }
        }        
      }).catch(err => {
        console.log(err);
      });
    } else {
      // this.logout();
    }
    /******Commented start
      // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      if(localStorage.getItem("userToken")){
        window.addEventListener("scroll", this.scrollvue);
        // Store for check permission
        let res = this.$store.dispatch("auth/getAuthUser");
          res.then(value => {        
            let allPermission = value.user.roles.all_permission; 
            this.ergopermission = allPermission.includes('site-ergo-ally-site-view');
            this.adminpermission  = allPermission.includes('site-access-admin-center-view'); 
            
            if(!this.ergopermission){
              this.videopermission = false;
              this.selfassessmentpermission = false;
              this.employeepermission = false;
            }else {
              if(allPermission.includes('site-video-page-only-view')){         
                this.videopermission = true;
              }
              if(allPermission.includes('dashboard-pages-self-assessment-view')){         
                this.selfassessmentpermission = true;
              }
              this.employeepermission = allPermission.includes('dashboard-pages-employee-dashboard-view');
            }
          }).catch(err => {
            console.log(1212,err);
          });
      }else{
        // this.logout();
      }
      End **************************/
  },
  mounted() {
    this.base_url = process.env.VUE_APP_API_URL;
    let data = this.$store.getters["auth/authUser"];
    if(data.user.region=='' || data.user.region== null){
      this.isRegionModalVisible=true;
      var body = document.body;
      body.classList.add("hide_scroll");
      var arrRegions = JSON.parse(data.client.client_regions);
      // var arrRegions = decodedData["regions"];
      for (let x in arrRegions) {
      this.regionsData .push({
        id: arrRegions[x], name: arrRegions[x]
        });
      }
    }
    let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === 'undefined') {
      console.log('no company values header')
    } else {
      this.company_logo = company_data.logo;
    }
    this.avatar = localStorage.getItem("user_profile");
    // For Hide Show Self-Assessment button
    if(company_data.site_menu_details.TakeASelfAssessment.status == 0){
      this.show_self_assessment_block = false;
    }

  },
  methods: {
    checkDomain() {
      if (localStorage.getItem("userToken")) {
        AuthService.userDomainCheck()
          .then((response) => {
            var decodedJson = this.decodeAPIResponse(response.data.data);
            this.clientsData = decodedJson.clients;
            this.isModalVisible = true;
            const body = document.querySelector("body");
            body.style.overflow = "hidden";
          }).catch(({ response }) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    removeUser() {
      if (localStorage.getItem("userToken")) {
        AuthService.removeUser()
          .then((response) => {
            if (response.data.delete == 0) {
              this.isModalVisible = false;
              const body = document.querySelector("body");
              body.style.removeProperty("overflow");
            }
          }).catch(({ response }) => {
            this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              this.$router.push({ name: "login" });
            }
          });
        } else {
        this.tokenExpired();
      }
    },
    logoutBackupR() {
      this.$store.dispatch("auth/logout");
    },
    manageTogglerCross() {
      document.getElementById("manageTogglerCross").classList.add("collapsed");
      document.getElementById("navbarSupportedContent1").classList.remove("show");
      if (document.getElementById("manageTogglerCross").getAttribute("aria-expanded") == "true") {
        document.getElementById("manageTogglerCross").setAttribute("aria-expanded", false);
        document.getElementById("navbarSupportedContent").classList.remove("show");
        document.querySelector('body').style.overflow = "auto";
      }

    },
    logout() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      AuthService.logout()
        .then(() => {
          var LoginType = window.localStorage.getItem("loginType");
          window.localStorage.removeItem("user_profile");
          window.localStorage.removeItem("id_token_client");
          window.localStorage.removeItem("userToken");
          window.localStorage.removeItem("companyInformation");
          window.localStorage.removeItem("loginType");
          window.localStorage.removeItem("employeeEmail");
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if(LoginType == 1) {
            this.$router.push({
              name: "home",
            });
          }else{
            this.$router.push({
              name: "login",
            });
          }
        })
        .catch((error) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.error = getError(error);
          const errType = Object.prototype.toString
            .call(getError(error))
            .slice(8, -1)
            .toLowerCase();
          if (errType === "string") {
            this.checkType = true;
          }
        });
    },
    scrollvue() {
      let navbar = document.querySelector(".navbar");
      this.divscrolltop = window.scrollY;
      if (this.divscrolltop > "50") {
        navbar.classList.add("scroll-header");
      } else {
        navbar.classList.remove("scroll-header");
      }
    },
    checkMenu: function (event) {
      if (event.currentTarget.getAttribute("aria-expanded") == "true") {
        this.open = true;
        document.querySelector('body').classList.remove('overflow-auto');
      } else {
        this.open = false;
      }
    },
    changeVal() {
      var x = document.getElementById("manageTogglerCross").getAttribute("aria-expanded");
      if (x == "false") {
        x = "true"
      } else {
        x = "false"
        document.getElementById('manageTogglerCross').classList.add('collapsed');
        document.getElementById('navbarSupportedContent').classList.remove('show');
        document.querySelector('body').classList.add('overflow-auto');

      }
      document.getElementById("manageTogglerCross").setAttribute("aria-expanded", x);
    },
    closemob() {
      document.getElementById("navbarSupportedContent").classList.remove("show");
      document.getElementById("manageTogglerCross").setAttribute("aria-expanded", false);
      document.getElementById("manageTogglerCross").classList.add("collapsed");
    },

  },
};
</script>