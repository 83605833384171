import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store/index";
// import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import common from "@/middleware/common";
import superadmin from "@/middleware/superadmin";
// import middlewarePipeline from "@/router/middlewarePipeline";
import vSelect from 'vue-select';
import CKEditor from '@ckeditor/ckeditor5-vue2';
// import axios from "axios";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import store from '@/store/index.js';


Vue.use(VueToast);

Vue.use(VueRouter);
Vue.component('v-select', vSelect);
Vue.use(CKEditor);

const routes = [
  { 
    path: "*",
    name: "404",
    component: () => import("../views/home/404.vue"),
    meta: {
      page_title: '404',
      is_header: 'home'
    }
  },
  { 
    path: "/user/sso-login/:token",
    name: "sso",
    component: () => import("../views/sso.vue"),
    meta: {
      page_title: 'SSO Login',
      is_header: ''
    }
  },
  { 
    path: "/user/select-client/:token/:multidomain?",
    name: "sso-select-client",
    component: () => import("../views/sso-select-client.vue"),
    meta: {
      page_title: 'SSO Login',
      is_header: ''
    }
  },
  {

    path: "/",
    name: "home",
    component: () => import("../views/home/Home.vue"),
    meta: {
      page_title: 'Home',
      is_header: 'home'
    }
  },
  {
    path: "/free-resources",
    name: "free_resources",
    component: () => import("../views/home/Free_resources.vue"),
    meta: {
      page_title: 'Free Resources',
      is_header: 'home'
    }
  },
  {
    path: "/downloads",
    name: "downloads",
    component: () => import("../views/home/Free_resources.vue"),
    meta: {
      page_title: 'Free Resources',
      is_header: 'home'
    }
  },
  {
    path: "/global_program",
    name: "global_program",
    component: () => import("../views/home/Global_program.vue"),
    meta: {
      page_title: 'Global Program',
      is_header: 'home'
    }
  },
  {
    path: "/learn-more",
    name: "learn_more",
    component: () => import("../views/home/Learn_more.vue"),
    meta: {
      page_title: 'Learn More',
      is_header: 'home'
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/home/Learn_more.vue"),
    meta: {
      page_title: 'Learn More',
      is_header: 'home'
    }
  },
  {
    path: "/media",
    name: "media",
    component: () => import("../views/home/Media.vue"),
    meta: {
      page_title: 'Media',
      is_header: 'home'
    }
  },
  {
    path: "/meet-the-team",
    name: "meet_the_team",
    component: () => import("../views/home/Meet_the_team.vue"),
    meta: {
      page_title: 'Meet the Team',
      is_header: 'home'
    }
  },
  {
    path: "/team-pbe",
    name: "team_pbe",
    component: () => import("../views/home/Meet_the_team.vue"),
    meta: {
      page_title: 'Meet the Team',
      is_header: 'home'
    }
  },
  {
    path: "/pbes-story",
    name: "our_story",
    component: () => import("../views/home/Our_story.vue"),
    meta: {
      page_title: 'Our Story',
      is_header: 'home'
    }
  },
  {
    path: "/return_to_office",
    name: "return_to_office",
    component: () => import("../views/home/Return_to_office.vue"),
    meta: {
      page_title: 'Return to Office',
      is_header: 'home'
    }
  },
  {
    path: "/return-to-office",
    name: "return_to_office",
    component: () => import("../views/home/Return_to_office.vue"),
    meta: {
      page_title: 'Return to Office',
      is_header: 'home'
    }
  },
  {
    path: "/self-assessments",
    name: "self_assessments",
    component: () => import("../views/home/Self_assessments.vue"),
    meta: {
      page_title: 'Self-Assessments',
      is_header: 'home'
    }
  },
  {
    path: "/online-solutions",
    name: "self_assessments",
    component: () => import("../views/home/Self_assessments.vue"),
    meta: {
      page_title: 'Self-Assessments',
      is_header: 'home'
    }
  },  
  {
    path: "/virtual-services",
    name: "virtual_service",
    component: () => import("../views/home/Virtual_service.vue"),
    meta: {
      page_title: 'Virtual Service',
      is_header: 'home'
    }
  },
  {
    path: "/onsite-services",
    name: "onsite_service",
    component: () => import("../views/home/Onsite_service.vue"),
    meta: {
      page_title: 'Onsite Services',
      is_header: 'home'
    }
  },
  {
    path: "/wellness-series",
    name: "wellness_series",
    component: () => import("../views/home/WellnessSeries.vue"),
    meta: {
      page_title: 'Wellness Series',
      is_header: 'home'
    }
  },
  {
    path: "/webinars",
    name: "webinars",
    component: () => import("../views/home/Webinars.vue"),
    meta: {
      page_title: 'Webinars',
      is_header: 'home'
    }
  },
  {
    path: "/blogs/en/blog",
    name: "blog",
    meta: {
      page_title: 'Blogs',
      is_header: 'home'
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy_policy",    
    meta: {
      page_title: 'Privacy Policy',
      is_header: 'home'
    },
    component: () => import("../views/home/Privacy_policy.vue"),
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",    
    meta: {
      page_title: 'Privacy Policy',
      is_header: 'home'
    },
    component: () => import("../views/home/Privacy_policy.vue"),
  },
  {
    path: "/terms-of-service",
    name: "terms_of_service",
    component: () => import("../views/home/Terms_of_service.vue"),
    meta: {
      page_title: 'Terms of Service',
      is_header: 'home'
    }
  },
  {
    path: "/terms_of_service",
    name: "terms_of_service",
    component: () => import("../views/home/Terms_of_service.vue"),
    meta: {
      page_title: 'Terms of Service',
      is_header: 'home'
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import('../views/home/Register.vue'),
    meta: {
      page_title: 'Registration',
      is_header: 'home'
    },
  },
  {
    path: "/no-acces-granted",
    name: "403",
    component: () => import('../views/home/403.vue'),
    meta: {
      page_title: '403',
      is_header: 'home'
    },
  },
  {
    path: "/page-not-found",
    name: "404",
    component: () => import('../views/home/404.vue'),
    meta: {
      page_title: '404',
      is_header: 'home'
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/home/Login.vue"),
  //   meta: {
  //     page_title: 'Login',
  //     is_header: 'home'
  //   }
  // },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      page_title: 'Forgot Password',
      is_header: 'home'
    }
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("../views/home/ResetPassword.vue"),
    meta: {
      page_title: 'Reset Password',
      is_header: 'home'
    }
  },
  //user moudle start from here
  {
    path: "/user",
    name: "user_home",
    meta: {
      page_title: 'User Home',
      is_header:'user_dashboard',
      is_permission: 'site-ergo-ally-site-view',
      middleware: [common]
    },
    component: () => import('../views/Home'), 
    beforeEnter: loginPermissionAuth
  },
  {
    path: "/user/profile",
    name: "profile",
    meta: { 
      page_title: 'User Profile',
      is_header:'user_dashboard',
      middleware: [common] },
      component: () =>
      import("../views/Profile"),
      beforeEnter: userAuth

  },
  {
    path: "/terms-of-use",
    name: "terms_of_use",
    meta: {
      page_title: 'Terms Of Use',
      is_header: 'home'
    },
      component: () =>
      import("../views/TermsOfUse"),
  },
  // {
  //   path: "/user/register",
  //   name: "register",
  //   meta: { 
  //     page_title: 'Employee Home',
  //   is_header:'user_dashboard',
  //   middleware: [guest] },
  //   component: () => import('../views/Register'),
    
  // },
  {
    path: "/user/login",
    name: "login",
    meta: {
      page_title: 'Login',
      is_header: 'login',
      middleware: [guest]
    },
    component: () => import("../views/Login"),
    beforeEnter: function(to, from, next){
      let pbergourl = window.location.origin;
      let pathname = window.location.pathname;
      if((pbergourl == "https://pbergo.com") || (pbergourl == "https://www.pbergo.com")){
        if(pathname.includes('user')  || pathname.includes('admin'))
        window.location.href = 'https://app.pbergo.com'+window.location.pathname;
        return ;
      }
      next();
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      page_title: 'Logout',
      is_header: 'logout',
    },
    component: () => import("../views/Logout"),
    
  },
  // {
  //   path: "/user/forgot-password",
  //   name: "forgotPassword",
  //   meta: { 
  //     page_title: 'Employee Home',
  //   is_header:'user_dashboard',
  //   middleware: [guest] },
  //   component: () =>
  //     import("../views/ForgotPassword"),
    
  // },
  {
    path: "/user/reset-password",
    name: "resetPassword",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () => import("../views/ResetPassword"),
    beforeEnter: userAuth  
  },
  // {
  //   path: "/user/dashboard",
  //   name: "dashboard",
  //   meta: { 
  //     page_title: 'Employee Home',
  //     is_header:'user_dashboard',
  //     middleware: [auth] },
  //   component: () => import("../views/client/Dashboard"),
  //   beforeEnter: userAuth
  // },
  {
    path: "/user/demo",
    name: "demo",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Demo.vue"),
      // beforeEnter: userAuth
  },
  {
    path: "/user/home_essentials",
    name: "home_essentials",
    meta: { 
      page_title: 'Home Essentials',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_essentials.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/seating",
    name: "seating",
    meta: { 
      page_title: 'Seating',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Seating.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/self_assessment_signup",
    name: "self_assessment_signup",
    meta: { 
      page_title: 'Self-Assessment Signup',
      is_header:'user_dashboard',     
      middleware: [common] },
    component: () =>
      import("../views/Self_assessment_signup.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/self_assessment",
    name: 'self_assessment',
    meta: { 
      page_title: 'Take a Self-Assessment',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Self_assessment.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/style_guide",
    name: "style_guide",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Style_guide.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/wfo_seating",
    name: "wfo_seating",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Wfo_seating.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/work_from_anywhere",
    name: "work_from_anywhere",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Work_from_anywhere.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/work_from_office",
    name: "work_from_office",
    meta: { 
      page_title: 'Work From Office',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Work_from_office.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/work_from_home",
    name: "work_from_home",
    meta: { 
      page_title: 'Work From Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Work_from_home.vue"),
      beforeEnter: userAuth
  },  
  {
    path: "/user/wfh_seating",
    name: "home_essentials_seating",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_essentials_seating.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/hybrid_Working",
    name: "hybrid",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Hybrid.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/mobility_kits",
    name: "mobility_kits",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Mobility_kits.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/home_stretches",
    name: "home_stretches",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_stretches.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/everyday_stretches",
    name: "everyday_stretches",
    meta: { 
      page_title: 'Everyday Stretches',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Everyday_stretches.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/wfo_desk",
    name: "seating_your_desk",
    meta: { 
      page_title: 'Desk',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Seating_your_desk.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/manage_your_schedule",
    name: "hybird_working_manage_your_schedule",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Hybird_working_manage_your_schedule.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/setup_guidelines",
    name: "setup_guidline",
    meta: { 
      page_title: 'Setup Guidelines',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Setup_guidline.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/work_better",
    name: "work_better",
    meta: { 
      page_title: 'Work Better',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Work_better.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/ergo_essentials",
    name: "ergo_essentials",
    meta: { 
      page_title: 'Ergo Essentials',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Ergo_essentials.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/schedule_eval",
    name: "schedule_eval",
    meta: { 
      page_title: 'Schedule Eval',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Schedule_eval.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/increased_energy_beauty_sleep",
    name: "beauty_sleep",
    meta: { 
      page_title: 'Beauty Sleep',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Beauty_sleep.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/work_from_lab",
    name: "work_from_lab",
    meta: { 
      page_title: 'Work From Lab',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Work_from_lab.vue"),
      beforeEnter: userAuth
  },


  {
    path: "/user/increased_energy_clean_eating",
    name: "clean_eating",
    meta: { 
      page_title: 'Increased Energy: Clean Eating',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Clean_eating.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/bench_work",
    name: "bench_work",
    meta: { 
      page_title: 'Bench Work',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Bench_work.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/posture_perfect",
    name: "posture_perfect",
    meta: { 
      page_title: 'Posture Perfect ',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Posture_perfect.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/sitting_pretty",
    name: "sitting_pretty",
    meta: { 
      page_title: 'Sitting Pretty',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Sitting_pretty.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/sit_stand_basics",
    name: "sit_stand_basics",
    meta: { 
      page_title: 'Sit/Stand Basics',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Sit_Stand_Desks.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/typing_dos_and_donts",
    name: "typing_dos_and_donts",
    meta: { 
      page_title: 'Typing Do’s and Don’ts',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Typing_Dos_and_Donts.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/mousing_dos_and_donts",
    name: "mousing_dos_and_donts",
    meta: { 
      page_title: 'Mousing Do’s and Don’ts',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Mousing_Dos_and_Donts.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/mindful_ergonomics",
    name: "mindful_ergonomics",
    meta: { 
      page_title: 'Mindful Ergonomics',
      is_header:'user_dashboard',
      is_access_of: 'Ergo Essentials',
      middleware: [common] },
    component: () =>
      import("../views/Mindful_Ergonomics.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/desk",
    name: "desk",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Desk.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/desktop_items",
    name: "desktop_items",
    meta: { 
      page_title: 'Desktop Items',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Desktop_Items.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/screens",
    name: "screens",
    meta: { 
      page_title: 'Screens',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Screens.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/accessories",
    name: "accessories",
    meta: { 
      page_title: 'Accessories',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Accessories.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/lighting",
    name: "lighting",
    meta: { 
      page_title: 'Lighting',
      is_header:'user_dashboard',
      is_access_of: 'Setup Guidelines',
      middleware: [common] },
    component: () =>
      import("../views/Lighting.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/pomodoro_how_it_works",
    name: "how_it_works",
    meta: { 
      page_title: 'Pomodoro: How it Works',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/How_it_Works.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/pomodoro_sit_and_stand",
    name: "sit_stand",
    meta: { 
      page_title: 'Pomodoro: Sit and Stand',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Sit_Stand.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/pomodoro_stretch_break",
    name: "stretch_break",
    meta: { 
      page_title: 'Pomodoro: Stretch Break',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Stretch_Break.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/sensory_overload_spotting_the_signs",
    name: "sensory_overload_spot",
    meta: { 
      page_title: 'Sensory Overload Spot',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Sensory_overload_spot.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/sensory_overload_digital_detox",
    name: "sensory_overload_digital",
    meta: { 
      page_title: 'Sensory Overload: Digital Detox',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Sensory_overload_digital.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/sensory_overload_eye_and_ear_fatigue",
    name: "sensory_overload_eye",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Sensory_overload_eye.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/productivity_hacks_structure_your_day",
    name: "productivity_structure",
    meta: { 
      page_title: 'Productivity Structure',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Productivity_structure.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/productivity_hacks_the_multitask_myth",
    name: "productivity_multitasking",
    meta: { 
      page_title: 'Productivity',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Productivity_multitasking.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/time_management_energy_vs_time",
    name: "time_management_energy",
    meta: { 
      page_title: 'Time Management Energy',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Time_management_energy.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/enhanced_focus_concentration",
    name: "enhanced_focus_concentration",
    meta: { 
      page_title: 'Enhanced Focus Concentration',
      is_header:'user_dashboard',
      is_access_of: 'Work Better',
      middleware: [common] },
    component: () =>
      import("../views/Enhanced_focus_concentration.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/increased_energy_exercise",
    name: "exercise",
    meta: { 
      page_title: 'Increased Energy: Exercise',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Exercise.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/time_management_tame_your_todolist",
    name: "time_management_todolist",
    meta: { 
      page_title: 'Time Management: Tame Your To Do List',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Time_management_todolist.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/enhanced_focus_avoiding_distractions",
    name: "enhanced_focus_avoiding",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Enhanced_focus_avoiding.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/enhanced_focus_deep_breathing",
    name: "enhanced_focus_deep",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Enhanced_focus_deep.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/video_library",
    name: "ergo_video_library",
    meta: { 
      page_title: 'Ergo Video Library',
      is_header:'user_dashboard',
      is_permission: 'site-video-page-only-view',
      is_video: true,
      middleware: [common] },
    component: () =>
      import("../views/Ergo_video_library.vue"),
    beforeEnter: permissionAuth  
      
  },
  {
    path: "/user/demo-register",
    name: "signup",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Signup.vue"),
      beforeEnter: userAuth
  },
  // {
  //   path: "/user/demo-forgot-password",
  //   name: "forgot_password",
  //   meta: { 
  //     page_title: 'Employee Home',
  //     is_header:'user_dashboard',
  //     middleware: [common] },
  //   component: () =>
  //     import("../views/Forgot_password.vue"),
  //     beforeEnter: userAuth
  // },
  {
    path: "/user/resetPassword2",
    name: "resetPassword2",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/ResetPassword2.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/terms_of_service",
    name: "terms_of_service",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Terms_of_service.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/privacy_policy",
    name: "privacy_policy",
    redirect: '/privacy-policy',
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/home/Privacy_policy.vue"),
      beforeEnter: userAuth
  },

  {
    path: "/user/password_encryption",
    name: "password_encryption",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/Password_encryption.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/desktop",
    name: "home_essentials_desktop",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_essentials_desktop.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/couches",
    name: "home_essentials_couches",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_essentials_couches.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/household_ergo",
    name: "home_essentials_household",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Home'],
      middleware: [common] },
    component: () =>
      import("../views/Home_essentials_household.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/keyboard_&_mouse",
    name: "seating_your_keybord",
    meta: { 
      page_title: 'Keyboard & Mouse',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Seating_your_keybord.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/monitor_&_screens",
    name: "seating_your_monitor",
    meta: { 
      page_title: 'Monitor & Screens',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Seating_your_monitor.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/meeting_rooms",
    name: "seating_your_meeting",
    meta: { 
      page_title: 'Meeting Rooms',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Office'],
      middleware: [common] },
    component: () =>
      import("../views/Seating_your_meeting.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/staying_focused",
    name: "staying_focused",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Staying_focused.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/30_second_setup",
    name: "second_setup",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Second_setup.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/anywhere_stretches",
    name: "anywhere_stretches",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Anywhere'],
      middleware: [common] },
    component: () =>
      import("../views/Anywhere_stretches.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/pipetting",
    name: "pipetting",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Pipetting.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/microscopes",
    name: "microscope",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Microscope.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/material_handling",
    name: "material_handling",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Material_handling.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/hood_work",
    name: "hood_work",
    meta: { 
      page_title: 'Hood Work',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Hood_work.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/lab_stretches",
    name: "lab_stretches",
    meta: { 
      page_title: 'Lab Stretches',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Lab'],
      middleware: [common] },
    component: () =>
      import("../views/Lab_stretches.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/my-reports",
    name: "my_reports",
    meta: { 
      page_title: 'Employee - My Reports',
      is_header:'user_dashboard',
      // middleware: [common],
      is_permission: 'dashboard-pages-employee-dashboard-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      // import("../views/employee_dashboard/My_Reports"),
      import("../views/employee_dashboard/Alchemer_Reports"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/my-reports/:email",
    name: "user_my_reports",
    meta: { 
      page_title: 'Employee - My Reports',
      is_header:'user_dashboard',
      middleware: [common],
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/My_Reports"),
      beforeEnter: userAuth
  },
  {
    path: "/user/help-center",
    name: "help_center",
    meta: {
      page_title: 'Employee - Help Center',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'dashboard-pages-employee-help-center-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Help_Center"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/self-assessment-activity",
    name: "self_assessment_activity",
    meta: { 
      page_title: 'Employee - Self-Assessment Activity',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'dashboard-pages-self-assessment-view',
      is_sidebar:'employee-sidebar', 
    },
    component: () =>
      import("../views/employee_dashboard/Self_Assessment_Activity"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/ergo-eval-activity",
    name: "ergo_eval_activity",
    meta: { 
      page_title: 'Employee - Ergo Eval Activity',
      is_header:'user_dashboard',
      middleware: [common],is_permission: 'dashboard-pages-ergo-eval-activity-view',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/Ergo_Eval_Activity"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/chart",
    name: "chart",
    meta: { 
      page_title: 'chart',
      is_header:'user_dashboard',
      middleware: [common] },
    component: () =>
      import("../views/employee_dashboard/chart"),
  },

  {
    path: "/user/superadmin-dashboard",
    name: "SuperadminDashboard",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [superadmin] },
    component: () =>
      import("../views/superadmin/SuperadminDashboard.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/roles",
    name: "roles",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [superadmin] },
    component: () =>
      import("../views/superadmin/roles/roles.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/roles/edit/:id",
    name: "editrole",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      middleware: [superadmin] },
    component: () =>
      import("../views/superadmin/roles/editrole.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/my-reports/viewreport/:url",
    name: "view_my_reports",
    meta: { 
      page_title: 'Employee - View My Reports',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      is_permission: 'dashboard-pages-employee-dashboard-view',
      middleware: [common] },
    component: () =>
      import("../views/employee_dashboard/view_reports"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/program-overview/:id?",
    name: "program_overview",
    meta: { 
      page_title: 'Employee - Program Overview',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'dashboard-pages-program-overview-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Program_Overview"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-program-overview/:id?",
    name: "client_program_overview",
    meta: { 
      page_title: 'Employee - Program Overview',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'dashboard-pages-program-overview-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Program_Overview"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/follow-up-feedback",
    name: "follow_up_feedback",
    meta: { 
      page_title: 'Employee - Follow-Up Feedback',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'dashboard-pages-follow-up-feedback-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Followup_Feedback"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/employee-search",
    name: "employee_search",
    meta: { 
      page_title: 'Employee - Search',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'dashboard-pages-employee-search-view',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/Employee_Search"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/view-employee/:id",
    name: "view_employee",
    meta: { 
      page_title: 'Employee - View Employee',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'dashboard-pages-employee-search-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/view-employee-prev/:id",
    name: "view_employee_prev",
    meta: { 
      page_title: 'Employee - View Employee',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'dashboard-pages-employee-search-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Prev"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/employee-reports/viewreport/:url",
    name: "view_employee_reports",
    meta: { 
      page_title: 'Employee - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'dashboard-pages-employee-search-view'
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Report"),
      beforeEnter: permissionAuth
  }, 
  {
    path: "/user/employee-prev-reports/viewreport/:url",
    name: "employee_reports_prev",
    meta: { 
      page_title: 'Employee - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'dashboard-pages-employee-search-view'
    },
    component: () =>
      import("../views/employee_dashboard/view_reports_prev"),
      beforeEnter: permissionAuth
  }, 
  {
    path: "/user/evaluator",
    name: "evaluator",
    meta: { 
      page_title: 'Employee - Evaluator',
      is_header:'user_dashboard',
      middleware: [common], is_permission: ['evaluator-client-list','evaluator-reports-in-review'],
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/Evaluator"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/evaluator-employee-search/:id",
    name: "evaluator_employee_search",
    meta: { 
      page_title: 'Evaluator - Employee Search',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'evaluator-client-list',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Employee_Search"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/evaluator-employee-view/:id",
    name: "evaluator_employee_view",
    meta: { 
      page_title: 'Evaluator - Employee View',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'evaluator-client-list',
      is_sidebar:'employee-sidebar', 
    },
    component: () =>
      import("../views/evaluator/View_Employee"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/evaluator-view-employee-prev/:id",
    name: "evaluator_employee_view_prev",
    meta: { 
      page_title: 'Evaluator - View Employee',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'evaluator-client-list',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Prev"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/evaluator-employee-reports/viewreport/:url",
    name: "evaluator_employee_reports",
    meta: { 
      page_title: 'Evaluator - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'evaluator-client-list'
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Report"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/evaluator-employee-prev-reports/viewreport/:url",
    name: "evaluator_employee_reports_prev",
    meta: { 
      page_title: 'Evaluator - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'evaluator-client-list'
    },
    component: () =>
    import("../views/employee_dashboard/view_reports_prev"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/new-evaluation/:cid/:uid",
    name: "new_evaluation",
    meta: { 
      page_title: 'Evaluator - New Evaluation',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'evaluator-start-new-evaluation'
    },
    component: () =>
      import("../views/evaluator/New_Evaluation"),
      beforeEnter: permissionAuth
  },
  //admin end routes
  {
    path: "/admin/audit-log-viewer",
    name: "audit_log_viewer",
    meta: {
      page_title: 'Admin - Audit Logs',
      is_header: 'admin',
      is_permission: 'audit-log-viewer-view'
    },
    component: () => import("../views/admin/Audit_log_viewer.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/blogs?user_id=:id",
    name: "blog_admin",
    meta: {
      page_title: 'blogs',
      is_header: 'admin'
    },
  },
  {
    path: "/admin/send_notification",
    name: "send_notification",
    meta: {
      page_title: 'Admin - Send Notification',
      is_header: 'admin',
      is_permission: 'send-notification-view'
    },
    component: () =>
      import("../views/admin/Send_notification.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/add_client",
    name: "add_client",
    meta: {
      page_title: 'Admin - Add Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin/Add_client.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/add_user",
    name: "add_user",
    meta: {
      page_title: 'Admin - Add User',
      is_header: 'admin',
      is_permission: 'users-edit'
    },
    component: () =>
      import("../views/admin/Add_user.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/sso_configuration",
    name: "sso_configuration",
    meta: {
      page_title: 'Send Configuration',
      is_header: 'admin'
    },
    component: () =>
      import("../views/admin/Sso_configuration.vue"),
  },
  {
    path: "/admin/user_group",
    name: "user_group",
    meta: {
      page_title: 'User Group',
      is_header: 'admin',
      is_permission: 'role-add'
    },
    component: () =>
      import("../views/admin/User_group.vue"),
    //beforeEnter: permissionAuth 
  },
  {
    path: "/admin/all_clients",
    name: "all_clients",
    meta: {
      page_title: 'Admin - All Clients',
      is_header: 'admin',
      is_permission: 'clients-view'
    },
    component: () =>
      import("../views/admin/All_Clients.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/all_users",
    name: "all_user",
    meta: {
      page_title: 'Admin - All Users',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () => import("../views/admin/All_User.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/all_user_group",
    name: "all_user_group",
    meta: {
      page_title: 'Admin - All Users Group',
      is_header: 'admin',
      is_permission: 'role-view'
    },
    component: () =>
      import("../views/admin/All_User_Group.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/add_Permission",
    name: "add_Permission",
    meta: {
      page_title: 'Admin - Add Permission',
      is_header: 'admin',
      is_permission: 'role-edit'
    },
    component: () =>
      import("../views/admin/Add_Permission.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_permission/:id",
    name: "edit_permission",
    meta: {
      page_title: 'Admin - Edit Permission',
      is_header: 'admin',
      is_permission: ['role-edit','role-view']
    },
    component: () =>
      import("../views/admin/Add_Permission.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_user/:id",
    name: "edit_user",
    meta: {
      page_title: 'Admin - Edit User',
      is_header: 'admin',
      is_permission: 'users-edit'
    },
    component: () =>
      import("../views/admin/Add_user.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/summary-reports/:id",
    name: "user_summary_reports",
    meta: {
      page_title: 'Admin - Summary Report',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () => import("../views/admin/User_summary_reports.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/viewreport/:userid/:id",
    name: "view_user_reports",
    meta: {
      page_title: 'Admin - Summary Report',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () =>import("../views/admin/view_reports"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/alchemer-summary-reports/:id",
    name: "alchemer_user_summary_reports",
    meta: {
      page_title: 'Admin - Alchemer Summary Report',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () => import("../views/admin/Alchemer_user_summary_reports.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/alchemerviewreport/:userid/:id",
    name: "alchemer_view_user_reports",
    meta: {
      page_title: 'Admin - Alchemer Summary Report',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () =>import("../views/admin/Alchemer_view_reports.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/alchemerdownloadreport/:userid/:id",
    name: "alchemer_download_user_reports",
    meta: {
      page_title: 'Admin - Alchemer Summary Report Download',
      is_header: 'admin',
      is_permission: 'users-view'
    },
    component: () =>import("../views/admin/Alchemer_summary_reports_download.vue"),
    beforeEnter: permissionAuth
  },
  // {
  //   path: "/admin/create_customer",
  //   name: "create_customer",
  //   meta: {
  //     page_title: 'Create Customer',
  //     is_header: 'admin',
  //     //is_permission: 'role-add'
  //   },
  //   component: () =>
  //     import("../views/admin/Create_customer.vue"),
  // },
  // {
  //   path: "/admin/client_Manager",
  //   name: "client_Manager",
  //   meta: {
  //     page_title: 'Program Management',
  //     is_header: 'admin',
  //     is_permission: 'dashboard-pages-client-managers-view'
  //   },
  //   component: () =>
  //     import("../views/admin/Client_Manager.vue"),
  //   //beforeEnter: permissionAuth 
  // },
  // {
  //   path: "/admin/employee",
  //   name: "employee",
  //   meta: {
  //     page_title: 'Program Management',
  //     is_header: 'admin',
  //     is_permission: 'role-add'
  //   },
  //   component: () =>
  //     import("../views/admin/Employee.vue"),
  // },
  // {
  //   path: "/admin/evaluator",
  //   name: "evaluator",
  //   meta: {
  //     page_title: 'Evaluator',
  //     is_header: 'admin',
  //     is_permission: 'role-add'
  //   },
  //   component: () =>
  //     import("../views/admin/Evaluator.vue"),
  // },
  // {
  //   path: "/admin/pbergo_tech",
  //   name: "pbergo_tech",
  //   meta: {
  //     page_title: 'Evaluator',
  //     is_header: 'admin',
  //     is_permission: 'role-add'
  //   },
  //   component: () =>
  //     import("../views/admin/PBergo_tech.vue"),
  // },
  {
    path: "/admin/email_template",
    name: "email_template",
    meta: {
      page_title: 'Admin - Email Templates',
      is_header: 'admin',
      is_permission: 'email-templates-view'
    },
    component: () =>
      import("../views/admin/Email_template.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/add_new_template",
    name: "add_new_template",
    meta: {
      page_title: 'Admin - Add Email Templates',
      is_header: 'admin',
      is_permission: 'email-templates-edit'
    },
    component: () =>
      import("../views/admin/Add_new_template.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_client/:id",
    name: "edit_client",
    meta: {
      page_title: 'Admin - Edit Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin/Add_client.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_client/self-assessment/:id",
    name: "edit_client_self_assessment",
    meta: {
      page_title: 'Admin - Edit Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin//summaryreports/Client_Self_Assessment.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_client/ergo-eval/:id",
    name: "edit_client_ergo_eval",
    meta: {
      page_title: 'Admin - Edit Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin//summaryreports/Client_Ergo_Eval.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_client/self-assessment-lab/:id",
    name: "edit_client_self_assessment_lab",
    meta: {
      page_title: 'Admin - Edit Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin//summaryreports/Client_Self_Assessment_Lab.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_client/ergo-eval-lab/:id",
    name: "edit_client_ergo_eval_lab",
    meta: {
      page_title: 'Admin - Edit Client',
      is_header: 'admin',
      is_permission: 'clients-edit'
    },
    component: () =>
      import("../views/admin//summaryreports/Client_Ergo_Eval_Lab.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/admin/edit_email_template/:id",
    name: "edit_new_template",
    meta: {
      page_title: 'Admin - Edit Email Template',
      is_header: 'admin',
      is_permission: 'email-templates-edit'
    },
    component: () =>
      import("../views/admin/Edit_new_template.vue"),
    beforeEnter: permissionAuth
  },
  /*{
    path: "/admin/client_activity",
    name: "client_activity",
    meta: {
      page_title: 'Client Activity',
      is_header: 'admin',
      is_permission: 'dashboard-pages-client-activity-view'
    },
    component: () =>
      import("../views/admin/Client_activity.vue"),
    beforeEnter: permissionAuth
  },*/
  // {
  //   path: "/admin/evaluators_PBE",
  //   name: "evaluators_PBE",
  //   meta: {
  //     page_title: 'Evaluators_PBE',
  //     is_header: 'admin',
  //     //is_permission: 'no-permission'
  //   },
  //   component: () =>
  //     import("../views/admin/Evaluators_PBE.vue"),
  //     //beforeEnter: permissionAuth 
  // },
  {
    path: "/admin/self_assesment",
    name: "self_assesment",
    meta: {
      page_title: 'Self_assesment',
      is_header: 'admin',
      is_permission: 'dashboard-pages-self-assessment-view'
    },
    component: () =>
      import("../views/admin/Self_assesment.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/airbnb",
    name: "airbnb",
    meta: { 
      page_title: 'Airbnb - Home', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/Home'),    
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-home-a45",
    name: "working-from-home-a45",
    meta: { 
      page_title: 'Airbnb - Working From Home',
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-from-home-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-home-a45/home-essentials-a45",
    name: "home-essentials-a45",
    meta: { 
      page_title: 'Airbnb - Home Essentials', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/home-essentials-a45'),
    beforeEnter: airbnbUserPermissionAuth 
     
  },
  {
    path: "/airbnb/working-from-home-a45/seating-a45",
    name: "seating-a45",
    meta: { 
      page_title: 'Airbnb - WFH Seating', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/seating-a45'),
    beforeEnter: airbnbUserPermissionAuth 
     
  },
  {
    path: "/airbnb/working-from-home-a45/desktop-a45",
    name: "desktop-a45",
    meta: { 
      page_title: 'Airbnb - Desktop', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/desktop-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-home-a45/couches-a45",
    name: "couches-a45",
    meta: { 
      page_title: 'Airbnb - Couches', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/couches-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-home-a45/household-ergo-a45",
    name: "household-ergo-a45",
    meta: { 
      page_title: 'Airbnb - Household Ergo', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/household-ergo-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-home-a45/home-stretches-a45",
    name: "home-stretches-a45",
    meta: { 
      page_title: 'Airbnb - Home Stretches', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/home-stretches-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45",
    name: "working-from-the-office-a45",
    meta: { 
      page_title: 'Airbnb - Working From Office', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-from-the-office-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/posture-perfect-a45",
    name: "posture-perfect-a45",
    meta: { 
      page_title: 'Airbnb - Posture Perfect', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/posture-perfect-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/seating-a45",
    name: "wfo_seating-a45",
    meta: { 
      page_title: 'Airbnb - WFO Seating', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/wfo_seating-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/your-desk-a45",
    name: "your-desk-a45",
    meta: { 
      page_title: 'Airbnb - Your Desk', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/your-desk-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/keyboard-mouse-a45",
    name: "keyboard-mouse-a45",
    meta: { 
      page_title: 'Airbnb - Keyboard Mouse', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/keyboard-mouse-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/monitors-screens-a45",
    name: "monitors-screens-a45",
    meta: { 
      page_title: 'Airbnb - Monitors Screens', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/monitors-screens-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-the-office-a45/everyday-stretches-a45",
    name: "everyday-stretches-a45",
    meta: { 
      page_title: 'Airbnb - Everyday Stretches', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/everyday-stretches-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },

  {
    path: "/airbnb/i-am-hybrid-a45",
    name: "i-am-hybrid-a45",
    meta: { 
      page_title: 'Airbnb - Hybrid Working', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/i-am-hybrid-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/hybrid-working-a45",
    name: "hybrid-working-a45",
    meta: { 
      page_title: 'Airbnb - Office and home', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/hybrid-working-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/staying-focused-a45",
    name: "staying-focused-a45",
    meta: { 
      page_title: 'Airbnb - Staying Focused', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/staying-focused-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/mobility-kits-a45",
    name: "mobility-kits-a45",
    meta: { 
      page_title: 'Airbnb - Mobility Kit', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/mobility-kits-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/working-from-the-cafe-a45",
    name: "working-from-the-cafe-a45",
    meta: { 
      page_title: 'Airbnb - Working From Cafe', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-from-the-cafe-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/managing-your-schedule-a45",
    name: "managing-your-schedule-a45",
    meta: { 
      page_title: 'Airbnb - Managing Your Schedule', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/managing-your-schedule-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/i-am-hybrid-a45/work-anywhere-stretches-a45",
    name: "work-anywhere-stretches-a45",
    meta: { 
      page_title: 'Airbnb - Stretches', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/work-anywhere-stretches-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-in-groups-a45",
    name: "working-in-groups-a45",
    meta: { 
      page_title: 'Airbnb - Working In Groups', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-in-groups-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-in-groups-a45/meeting-rooms-a45",
    name: "meeting-rooms-a45",
    meta: { 
      page_title: 'meeting-rooms-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/meeting-rooms-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-in-groups-a45/zoom-meetings-a45",
    name: "zoom-meetings-a45",
    meta: { 
      page_title: 'zoom-meetings-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/zoom-meetings-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-on-the-move-a45",
    name: "working-on-the-move-a45",
    meta: { 
      page_title: 'Airbnb - Working on the move', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-on-the-move-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-on-the-move-a45/airplane-travel-a45",
    name: "airplane-travel-a45",
    meta: { 
      page_title: 'Airbnb - Working on the move Airplane travel', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/airplane-travel-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-on-the-move-a45/self-care-a45",
    name: "self-care-a45",
    meta: { 
      page_title: 'Airbnb - Working on the move Self care', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/self-care-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-listings-a45",
    name: "working-from-listings-a45",
    meta: { 
      page_title: 'Airbnb - Working from an Airbnb', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/working-from-listings-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-listings-a45/what-to-pack-a45",
    name: "what-to-pack-a45",
    meta: { 
      page_title: 'what-to-pack-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/what-to-pack-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/working-from-listings-a45/posture-perfect-a45",
    name: "wfl-posture-perfect-a45",
    meta: { 
      page_title: 'posture-perfect-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/wfl-posture-perfect-a45'),
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/admin",
    name: "admin_center",
    meta: {
      page_title: 'Admin Center',
      is_header: 'admin',
      is_permission: 'site-access-admin-center-view',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/admin/Admin_center.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/airbnb/productivity-a45",
    name: "productivity-a45",
    meta: { 
      page_title: 'Airbnb - Productivity', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/productivity-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/increase-your-energy-a45",
    name: "increase-your-energy-a45",
    meta: { 
      page_title: 'Airbnb - Increase your energy', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/increase-your-energy-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/clean-eating-a45",
    name: "clean-eating-a45",
    meta: { 
      page_title: 'clean-eating-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/clean-eating-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/move-your-body-a45",
    name: "move-your-body-a45",
    meta: { 
      page_title: 'move-your-body-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/move-your-body-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/the-pomodoro-technique-a45",
    name: "the-pomodoro-technique-a45",
    meta: { 
      page_title: 'Airbnb - Pomodoro Technique', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/the-pomodoro-technique-a45'),     
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/sit-and-stand-a45",
    name: "sit-and-stand-a45",
    meta: { 
      page_title: 'sit-and-stand-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/sit-and-stand-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/stretch-breaks-a45",
    name: "stretch-breaks-a45",
    meta: { 
      page_title: 'stretch-breaks-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/stretch-breaks-a45'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/sensory-overload-a45",
    name: "sensory-overload-a45",
    meta: { 
      page_title: 'Airbnb - Sensory overload', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/sensory-overload-a45'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/digital-detox-a45",
    name: "digital-detox-a45",
    meta: { 
      page_title: 'digital-detox-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/digital-detox-a45'),     
    beforeEnter: airbnbUserPermissionAuth 
  },
  {
    path: "/airbnb/protect-your-eyes-and-ears-a45",
    name: "protect-your-eyes-and-ears-a45",
    meta: { 
      page_title: 'protect-your-eyes-and-ears-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/protect-your-eyes-and-ears-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/productivity-hacks-a45",
    name: "productivity-hacks-a45",
    meta: { 
      page_title: 'Airbnb - Productivity Hacks', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/productivity-hacks-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/multitasking-a45",
    name: "multitasking-a45",
    meta: { 
      page_title: 'multitasking-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/multitasking-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/time-management-a45",
    name: "time-management-a45",
    meta: { 
      page_title: 'Airbnb - Time Management', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/time-management-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/tame-your-to-do-list-a45",
    name: "tame-your-to-do-list-a45",
    meta: { 
      page_title: 'tame-your-to-do-list-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/tame-your-to-do-list-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/enhanced-focus-a45",
    name: "enhanced-focus-a45",
    meta: { 
      page_title: 'Airbnb - Enhanced focus', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/enhanced-focus-a45'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/avoiding-distractions-a45",
    name: "avoiding-distractions-a45",
    meta: { 
      page_title: 'avoiding-distractions-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/avoiding-distractions-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/focused-breathing-a45",
    name: "focused-breathing-a45",
    meta: { 
      page_title: 'focused-breathing-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/focused-breathing-a45'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/equipment-guides-a45",
    name: "equipment-guides-a45",
    meta: { 
      page_title: 'Airbnb - Equipment guides', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/equipment-guides-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/lighting-a45",
    name: "lighting-a45",
    meta: { 
      page_title: 'lighting-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/lighting-a45'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/screens-a45",
    name: "screens-a45",
    meta: { 
      page_title: 'Airbnb - Screens', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/screens-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/ergo-accessories-a45",
    name: "ergo-accessories-a45",
    meta: { 
      page_title: 'ergo-accessories-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/ergo-accessories-a45'),
    beforeEnter: airbnbUserPermissionAuth      
  },
  {
    path: "/airbnb/chairs-a45",
    name: "chairs-a45",
    meta: { 
      page_title: 'chairs-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/chairs-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/keyboard-mouse-eq-a45",
    name: "keyboard-mouse-eq-a45",
    meta: { 
      page_title: 'keyboard-mouse-eq-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/keyboard-mouse-eq-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/mobile-devices-a45",
    name: "mobile-devices-a45",
    meta: { 
      page_title: 'mobile-devices-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/mobile-devices-a45'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/get-an-assessment",
    name: "get-an-assessment-a45",
    meta: { 
      page_title: 'get-an-assessment-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/get-an-assessment-a45'), 
    // component: () => import('../views/Self_assessment_signup.vue'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/self-assessment",
    name: "self-assessment-a45",
    meta: { 
      page_title: 'self-assessment-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/self-assessment-a45'),   
    // component: () => import("../views/Self_assessment.vue"), 
    beforeEnter: airbnbUserPermissionAuth  
  },
  {
    path: "/airbnb/schedule-evaluation-a45",
    name: "schedule-evaluation-a45",
    meta: { 
      page_title: 'schedule-evaluation-a45', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/schedule-evaluation-a45'), 
    beforeEnter: airbnbUserPermissionAuth     
  },
  {
    path: "/airbnb/ergo-videos",
    name: "ergo-videos",
    meta: { 
      page_title: 'ergo-videos', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/ergo-videos'),
    beforeEnter: airbnbUserPermissionAuth      
  },
  {
    path: "/airbnb/privacy-policy",
    name: "privacy-policy",
    meta: { 
      page_title: 'privacy-policy', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/privacy-policy'),  
    beforeEnter: airbnbUserPermissionAuth    
  },
  {
    path: "/airbnb/terms-of-serivce",
    name: "terms-of-serivce",
    meta: { 
      page_title: 'terms-of-serivce', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/terms-of-serivce'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/profile",
    name: "airbnb_profile",
    meta: { 
      page_title: 'Profile', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/profile'),   
    beforeEnter: airbnbUserPermissionAuth   
  },
  {
    path: "/airbnb/employee_dashboard/my_report",
    name: "airbnb_my_report",
    meta: { 
      page_title: 'Profile', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/employee_dashboard/My_Reports'),    
    beforeEnter: airbnbUserPermissionAuth  
  },
  {
    path: "/airbnb/employee_dashboard/help_center",
    name: "airbnb_help_center",
    meta: { 
      page_title: 'Help Center', 
      is_header: 'airbnb'
    },
    component: () => import('../views/airbnb/employee_dashboard/Help_Center'),    
    beforeEnter: airbnbUserPermissionAuth  
  },

  // {
  //   path: "/user/alchemer-reports",
  //   name: "alchemer_reports",
  //   meta: { 
  //     page_title: 'Employee - Alchemer Reports',
  //     is_header:'user_dashboard',
  //     middleware: [common], 
  //     is_permission: 'dashboard-pages-employee-dashboard-view',
  //     is_sidebar:'employee-sidebar',
  //   },
  //   component: () =>
  //     import("../views/employee_dashboard/Alchemer_Reports"),
  //   beforeEnter: permissionAuth
  // },

  {
    path: "/user/alchemer-reports/viewreport/:url",
    name: "view_alchemer_reports",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      is_permission: 'dashboard-pages-employee-dashboard-view',
      middleware: [common] },
    component: () =>
      import("../views/employee_dashboard/Alchemer_view_reports"),
      beforeEnter: permissionAuth
  }, 
 
  {
    path: "/user/client-activity",
    name: "client_activity",
    meta: {
      page_title: 'Employee - Client Activity',
      is_header: 'user_dashboard',
      is_permission: 'dashboard-pages-client-activity-view',
      is_sidebar:'employee-sidebar',
    },
    component: () => import("../views/pbe_management/Client_activity.vue"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-manager",
    name: "client_manager",
    meta: {
      page_title: 'Program Management',
      is_header: 'user_dashboard',
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'employee-sidebar',
    },
    component: () => import("../views/pbe_management/Client_Manager.vue"),
    beforeEnter: permissionAuth 
  },
    {
    path: "/user/client-employee-search/:id?",
    name: "client_employee_search",
    meta: { 
      page_title: 'Program Management - Employee Search',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Employee_Search"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-reports/:id?",
    name: "client_reports",
    meta: { 
      page_title: 'Program Management - Reports',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar', 
    },
    component: () =>
      import("../views/pbe_management/Client_Report"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-request/:id?",
    name: "client_requests",
    meta: { 
      page_title: 'Program Management - Requests',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar',
    },
    component: () =>
      import("../views/pbe_management/Client_Requests"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-followups/:id?",
    name: "client_followups",
    meta: { 
      page_title: 'Program Management - Followups',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar',
    },
    component: () =>
      import("../views/pbe_management/Client_Follow-Ups"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-view-employee/:clientid/:id",
    name: "client_employee_view",
    meta: { 
      page_title: 'Program Management - Employee Search Details',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-view-employee-prev/:id",
    name: "client_employee_view_prev",
    meta: { 
      page_title: 'Program Management - View Employee',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'dashboard-pages-client-managers-view',
      is_sidebar:'clientmanager-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Prev"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/client-employee-reports/viewreport/:url",
    name: "client_employee_reports",
    meta: { 
      page_title: 'Program Management - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'dashboard-pages-client-managers-view'
    },
    component: () =>
      import("../views/employee_dashboard/View_Employee_Report"),
      beforeEnter: permissionAuth
  },  
  {
    path: "/user/client-employee-prev-reports/viewreport/:url",
    name: "client_employee_reports_prev",
    meta: { 
      page_title: 'Program Management - View Employee Report',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      is_permission: 'dashboard-pages-client-managers-view'
    },
    component: () =>
      import("../views/employee_dashboard/view_reports_prev"),
      beforeEnter: permissionAuth
  }, 
  { 
    path: "/user/sso-login-error/:error",
    name: "sso-error",
    component: () => import("../views/sso-error.vue"),
    meta: {
      page_title: 'SSO Error Page',
      is_header: ''
    }
  },
  {
    path: "/user/editsurvey/:url",
    name: "view_edit_survey",
    meta: { 
      page_title: 'Edit Survey',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['evaluator-reports-in-review'] 
    },
    component: () =>
      import("../views/employee_dashboard/View_edit_survey"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/editclientreport/:url",
    name: "view_edit_client_report",
    meta: { 
      page_title: 'Edit Report',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['dashboard-pages-client-managers-view'] 
    },
    component: () =>
      import("../views/employee_dashboard/View_edit_client_report"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/dashboard-survey-reports/Self-Assessment",
    name: "dashboard_survey_reports_SA",
    meta: {
      page_title: 'Dashboard Survey Reports',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['dashboard-pages-program-overview-view', 'dashboard-pages-self-assessment-view'],
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Survey_Reports_blocks"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/dashboard-survey-reports/Ergo-Eval",
    name: "dashboard_survey_reports_EE",
    meta: {
      page_title: 'Dashboard Survey Reports',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['dashboard-pages-program-overview-view', 'dashboard-pages-self-assessment-view', 'dashboard-pages-ergo-eval-activity-view'],
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Survey_Reports_blocks"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/dashboard-scheduled-requests/:type?",
    name: "dashboard_scheduled_requests",
    meta: {
      page_title: 'Dashboard Scheduled Requests',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['dashboard-pages-program-overview-view','dashboard-pages-self-assessment-view'],
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/Scheduled_Requests_blocks"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/program-overview-reports/:url",
    name: "program_overview_alchemer_reports",
    meta: {
      page_title: 'Program Overview My Reports',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: ['dashboard-pages-program-overview-view','dashboard-pages-self-assessment-view','dashboard-pages-ergo-eval-activity-view'],
    },
    component: () =>
      import("../views/employee_dashboard/Program_overview_view_reports"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-self-assessment",
    name: "custom_search_self_assessment",
    meta: { 
      page_title: 'Custom Search - Self-Assessment',
      is_header:'user_dashboard',
      middleware: [common], 
      is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/custom_search/CustomSearchSelfAssessment.vue"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-ergo-eval",
    name: "custom_search_ergo_eval",
    meta: { 
      page_title: 'Custom Search - Ergo Eval',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/custom_search/CustomSearchErgoEval.vue"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-schedule-request",
    name: "custom_search_schedule_request",
    meta: { 
      page_title: 'Custom Search - Schedule-Request',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/custom_search/CustomSearchScheduleRequest.vue"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-follow-up",
    name: "custom_search_follow_up",
    meta: { 
      page_title: 'Custom Search - Follow-up',
      is_header:'user_dashboard',
      middleware: [common], is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
      
    component: () =>
      import("../views/employee_dashboard/custom_search/CustomSearchFollowup.vue"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-self-assessment/:id/:clientId",
    name: "search_survey_reports_SA",
    meta: {
      page_title: 'Custom Search - View Self-Assessment',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/custom_search/Survey_Reports_View"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-ergo-eval/:id/:clientId",
    name: "search_survey_reports_EE",
    meta: {
      page_title: 'Custom Search - View Ergo Eval',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/custom_search/Survey_Reports_View"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-follow-up/:id/:clientId",
    name: "search_survey_reports_Fu",
    meta: {
      page_title: 'Custom Search - View Follow Up',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/custom_search/Followup_View"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-schedule-request/:id/:clientId",
    name: "search_survey_reports_SR",
    meta: {
      page_title: 'Custom Search - View Schedule Request',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'custom-search',
      is_sidebar:'employee-sidebar',
    },
    component: () =>
      import("../views/employee_dashboard/custom_search/ScheduleRequest_View"),
    beforeEnter: permissionAuth
  },
  {
    path: "/user/custom-search-reports/:url",
    name: "custom_search_reports",
    meta: {
      page_title: 'Custom Search Reports',
      is_header:'user_dashboard',
      middleware: [common],
      is_permission: 'custom-search'
    },
    component: () =>
      import("../views/employee_dashboard/Program_overview_view_reports"),
      beforeEnter: permissionAuth
  },
  {
    path: "/user/travel-for-work",
    name: "travel_for_work",
    meta: { 
      page_title: 'Travel for Work',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Travel-for-work.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/airplane-travel",
    name: "airplane_travel",
    meta: { 
      page_title: 'Airplane Travel',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Airplane-travel.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/on-the-road",
    name: "on_the_road",
    meta: { 
      page_title: 'On the Road',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/On-the-road.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/overnight-travel",
    name: "overnight_travel",
    meta: { 
      page_title: 'Overnight Travel',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Overnight-travel.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/working-on-the-go",
    name: "working_on_the_go",
    meta: { 
      page_title: 'Working On the Go',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Working-on-the-go.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/self-care",
    name: "self_care",
    meta: { 
      page_title: 'Self Care',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Self-care.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/feel-good-stretches",
    name: "feel_good_stretches",
    meta: { 
      page_title: 'Feel Good Stretches',
      is_header:'user_dashboard',
      is_access_of: ['Home Menu','Travel'],
      middleware: [common] },
    component: () =>
      import("../views/Feel-good-stretches.vue"),
      beforeEnter: userAuth
  },
  {
    path: "/user/:url", //please keep this route at last most position, because it conflicts other routes of user dashboard 
    name: "view_equipment_recommended",
    meta: { 
      page_title: 'Employee Home',
      is_header:'user_dashboard',
      is_view_port:'viewreport',
      middleware: [common],
      // is_sidebar:'employee-sidebar',
    },
    component: () =>
      // import("../views/employee_dashboard/Alchemer_Reports"),
      import('../views/Home'),
      beforeEnter: userAuth
  },
  {
    path: '/too-many-meetings',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_API_URL +"/en/blog/too_many_meetings";
    }
  },
  {
    path: '/when-noise-takes-you-off-your-game/',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_API_URL +"/en/blog/when_noise_takes_you_off_your_game";
    }
  },
  {
    path: '/ergo-essentials-for-now-and-the-future',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_API_URL +"/en/blog/ergo_essentials_for_now_and_the_future";
    }
  },
  {
    path: '/returning-to-the-office-are-you-ergo-prepared',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_API_URL +"/en/blog/returning_to_the_office";
    }
  },
  {
    path: '/blog',
    beforeEnter: () => {
      window.location.href = process.env.VUE_APP_API_URL +"/en/blog";
    }
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

// router.beforeEach((to, from, next) => {
//   const middleware = to.meta.middleware;
//   const context = { to, from, next, store };

//   if (!middleware) {
//     return next();
//   }

//   middleware[0]({
//     ...context,
//     next: middlewarePipeline(context, middleware, 1),
//   });
// });

router.onError(error => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

function permissionAuth(to, from, next) { 
  let pbergourl = window.location.origin;
  let pathname = window.location.pathname;
  if((pbergourl == "https://pbergo.com") || (pbergourl == "https://www.pbergo.com")){
    if(pathname.includes('user')  || pathname.includes('admin'))
    window.location.href = 'https://app.pbergo.com'+window.location.pathname;
    return ;
  }
  if (localStorage.getItem("userToken")) {
    let permission = to.meta.is_permission;
    let header = to.meta.is_header;
    let config = store.dispatch("auth/getAuthUser"); 
      config.then(data => {
        let ssoStatus = data.user.sso_status;
          if(ssoStatus == 1){
              router.push({
                name: 'sso-select-client', 
                params: { token: localStorage.getItem("userToken"), multidomain : 2}
            });
          }
        let allPermission = data.user.roles.all_permission;
        if(header == 'admin'){ 
          if(!allPermission.includes('site-access-admin-center-view')){
            if(allPermission.includes('dashboard-pages-employee-dashboard-view')){
              next('/user/my-reports');
              return false;
            }
            if(!allPermission.includes('site-ergo-ally-site-view')){
              removeLogin();
              next('/no-acces-granted');
            }else{
              if(data.client.company_code.toLowerCase()=='airbnb')
                next('/airbnb');
              else
                next('/user');
              return false;
            }            
          }
        }

        // if(header == 'user_dashboard'){ 
        //   console.log("user dashboard");        
        //   if(!allPermission.includes('site-ergo-ally-site-view')){
        //     if(video_page){
        //       console.log("in video");
        //     }else             
        //     if(!allPermission.includes('site-access-admin-center-view')){
        //       removeLogin();
        //       next('/no-acces-granted');
        //     }else{
        //       next('/admin');
        //       return false;
        //     }            
        //   }
        // }
        var res = false;
        let check_array = Array.isArray(permission);
        if(check_array){
          permission.forEach(function(value) {
            if(allPermission.includes(value)){ 
              res = true;                
            }
          }); 
        }else{            
          res = allPermission.includes(permission);
        }  
        if (res == true) {          
          next();
        } else {            
          next('/no-acces-granted');
        }
        
      });
  } else { 
    removeLogin();
    router.push({name:"login"});
  }
}
/** added for tracking the view of pages using matomo */
router.afterEach((to) => {
  window._paq.push(['setDocumentTitle', "PBErgo - "+to.meta.page_title]);
  window._paq.push(['trackPageView']);
});

function userAuth(to, from, next) {
  let pbergourl = window.location.origin;
  let pathname = window.location.pathname;
  if((pbergourl == "https://pbergo.com") || (pbergourl == "https://www.pbergo.com")){
    if(pathname.includes('user')  || pathname.includes('admin'))
    window.location.href = 'https://app.pbergo.com'+window.location.pathname;
    return ;
  }
  if (localStorage.getItem("userToken")) {    
    let header = to.meta.is_header;
    let config = store.dispatch("auth/getAuthUser");
    config.then(data => {
        let ssoStatus = data.user.sso_status;
          if(ssoStatus == 1){
              router.push({
                name: 'sso-select-client', 
                params: { token: localStorage.getItem("userToken"), multidomain : 2}
            });
          }
        let allPermission = data.user.roles.all_permission;        
        if(header == 'user_dashboard'){ 
          if((to.name == 'self_assessment_signup' || to.name == 'self_assessment') && data.client.site_menu_details?.TakeASelfAssessment?.status == 0){
            next('/page-not-found');
          }
          let view_port = to.meta.is_view_port;
          if(view_port == 'viewreport'){
            if(!allPermission.includes('dashboard-pages-employee-dashboard-view')){
              next('/no-acces-granted');
            }
          }
          if(!allPermission.includes('site-ergo-ally-site-view')){
            if(!allPermission.includes('site-access-admin-center-view')){
              if(to.name == 'profile'){
                next();
                // next('user/profile');
              }else{
                next('/no-acces-granted');
              }
              // next('/no-acces-granted');
              
            }else{
              if(to.name == 'profile'){
                next();
              }else{
                next('/admin');
              }
            }            
          }else{ 
            let is_access_of = to.meta.is_access_of;
            if(is_access_of){
              // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
              let company_data = data.client;
              var allAccess = company_data.is_access_of;
              var res = false;
              let check_array = Array.isArray(is_access_of);
              if(check_array){
                res = is_access_of.every(elem => allAccess.includes(elem))
                /*is_access_of.forEach(function(value) {
                  if(allAccess.includes(value)){ 
                    res = true;                
                  }
                }); */
              }else{            
                res = allAccess.includes(is_access_of);
              }
              if (res == true) {          
                next();
              } else {            
                next('/page-not-found');
              }
            } else {
              next();
            }
          }
        }
      });
  } else { 
    removeLogin();
    router.push({name:"login"});
  }
}

// After login redirection.
function loginPermissionAuth(to, from, next) {
  let pbergourl = window.location.origin;
  let pathname = window.location.pathname;
  if((pbergourl == "https://pbergo.com") || (pbergourl == "https://www.pbergo.com")){
    if(pathname.includes('user')  || pathname.includes('admin'))
    window.location.href = 'https://app.pbergo.com'+window.location.pathname;
    return ;
  }
  if (localStorage.getItem("userToken")) {
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    let config = store.dispatch("auth/getAuthUser");
      config.then(data => {
        let ssoStatus = data.user.sso_status;
        if(ssoStatus == 1){
            router.push({
              name: 'sso-select-client', 
              params: { token: localStorage.getItem("userToken"), multidomain : 2}
          });
        }  
      let company_data = data.client;
      let allPermission = data.user.roles.all_permission;
        if(allPermission.includes('site-ergo-ally-site-view')){
          if (company_data.site_menu_details != undefined) {
            if(company_data.site_menu_details.Home!= undefined && company_data.site_menu_details.Home.status==1){
              next();
            } else if(company_data.site_menu_details.ErgoEssentials!= undefined && company_data.site_menu_details.ErgoEssentials.status==1){
              next('/user/ergo_essentials');
            } else if(company_data.site_menu_details.SetupGuidelines!= undefined && company_data.site_menu_details.SetupGuidelines.status==1){
              next('/user/setup_guidelines');
            } else if(company_data.site_menu_details.WorkBetter!= undefined && company_data.site_menu_details.WorkBetter.status==1){
              next('/user/work_better');
            } else {
              next('/user/profile');
            }
          }
          next();
        }else if(allPermission.includes('site-access-admin-center-view')){
          next('/admin');
          return false;
        }else if(allPermission.includes('site-video-page-only-view')){
          next('/user/video_library');
          return false;
        }else if(allPermission.includes('dashboard-pages-employee-dashboard-view')){
          next('/user/my-reports');
          return false;
        } else if(allPermission.includes('dashboard-pages-program-overview-view')){
          next('/user/program-overview');
          return false;
        } else if(allPermission.includes('dashboard-pages-self-assessment-view')){
          next('/user/self-assessment-activity');
          return false;
        } else if(allPermission.includes('dashboard-pages-ergo-eval-activity-view')){
          next('/user/ergo-eval-activity');
          return false;
        } else if(allPermission.includes('dashboard-pages-follow-up-feedback-view')){
          next('/user/follow-up-feedback');
          return false;
        } else if(allPermission.includes('dashboard-pages-employee-search-view')){
          next('/user/employee-search');
          return false;
        } else if(allPermission.includes('evaluator-client-list') || allPermission.includes('evaluator-reports-in-review')) {
          next('/user/evaluator');
          return false;
        } else if(allPermission.includes('dashboard-pages-client-activity-view')){
          next('/user/client-activity');
          return false;
        } else if(allPermission.includes('dashboard-pages-client-managers-view')){
          next('/user/client-manager');
          return false;
        } else if(allPermission.includes('dashboard-pages-employee-help-center-view')){
          next('/user/help-center');
          return false;
        }else{ 
          next('/user/profile');
          return false;
        }        
      });
  }else{
    removeLogin();
    router.push({name:"login"});
  }

}

function removeLogin(){
    Vue.$toast.error("Session Expired. Please log in again.", {
        position: "top-right",
    });  
    window.localStorage.removeItem("user_profile");
    window.localStorage.removeItem("id_token_client");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("companyInformation");
    window.localStorage.removeItem("loginType");
    window.localStorage.removeItem("employeeEmail");
}
function airbnbUserPermissionAuth(to, from, next) {
  let pbergourl = window.location.origin;
  if((pbergourl == "https://pbergo.com") || (pbergourl == "https://www.pbergo.com")){
    window.location.href = 'https://app.pbergo.com'+window.location.pathname;
    return ;
  }
  if (localStorage.getItem("userToken")) {
    let config = store.dispatch("auth/getAuthUser");
      config.then(data => {
        let ssoStatus = data.user.sso_status;
          if(ssoStatus == 1){
              router.push({
                name: 'sso-select-client', 
                params: { token: localStorage.getItem("userToken"), multidomain : 2}
            });
          }
        if(data.client.company_code.toLowerCase()=='airbnb'){         
          let allPermission = data.user.roles.all_permission;
          if(allPermission.includes('site-ergo-ally-site-view')){
            next();
          }else if(allPermission.includes('site-access-admin-center-view')){
            next('/admin');
            return false;
          }
          /*else if(allPermission.includes('site-video-page-only-view')){
            // next('/user/video_library');
            return false;
          }*/
          else if(allPermission.includes('dashboard-pages-employee-dashboard-view')){
            next('/user/my-reports');
            return false;
          }else if(allPermission.includes('dashboard-pages-employee-help-center-view')){
            next('/user/help-center');
            return false;
          }else{ 
            next('/user/profile');
            return false;
          }

          /** Hide.Show direct access of self-assessment pages */
          if((to.name == 'get-an-assessment-a45' || to.name == 'self-assessment-a45') && data.client.site_menu_details?.TakeASelfAssessment?.status == 0){
            next('/page-not-found');
          }

        } else {
          router.push({name:"login"});
        }

        

      });
  }else{
    removeLogin();
    router.push({name:"login"});
  }
}
export default router;