<template>
  <!-- <div> -->
  <!-- <div class="dashboard_section"> -->
  <!-- <div class="container-fluid"> -->
  <!-- <div class="row"> -->
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block border-0 sidebar navbar_footer">
    <button type="button" class="btn btn_bottom minus collapsed d-lg-none d-md-none d-sm-block" data-bs-toggle="collapse"
             data-bs-target="#navbarSupportedContent1" @click="zindexup" aria-controls="navbarSupportedContent1" aria-expanded="false"
             aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 35.575 38.631"  style=""><g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)"><path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path><path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"></path></g></svg>
          </button>
      <div class="d-md-block navbar-collapse  collapse" id="navbarSupportedContent1">
      <div class="position-sticky pt-3">
         <button type="button" class="btn_cross  minus collapsed d-lg-none d-md-none" data-bs-toggle="collapse"
             data-bs-target="#navbarSupportedContent1" @click="zindexupcross" aria-controls="navbarSupportedContent1" aria-expanded="false"
             aria-label="Toggle navigation">
          X
          </button>
      <ul class="nav flex-column">
        <li  @click="manageTogglerCross" v-show="userRole" class="nav-item">
          <router-link :class="
              $route.name == 'all_user_group' ||
              $route.name == 'add_Permission' ||
              $route.name == 'edit_permission'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'all_user_group' }" aria-current="page">
            <span data-feather="home">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="_Users_Role" data-name=" Users Role"
                  d="M13,21V11h8V21ZM3,13V3h8V13Zm6-2V5H5v6ZM3,21V15h8v6Zm2-2H9V17H5Zm10,0h4V13H15ZM13,3h8V9H13Zm2,2V7h4V5Z"
                  transform="translate(-3 -3)" />
              </svg>
            </span>
            Users Role
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="users_permission" class="nav-item">
          <router-link :class="
              $route.name == 'user_summary_reports' || 
              $route.name == 'all_user' ||
              $route.name == 'add_user' ||
              $route.name == 'edit_user' ||
              $route.name == 'alchemer_user_summary_reports'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'all_user' }" aria-current="page">
            <span data-feather="home">
              <svg id="_User" data-name=" User" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18">
                <path id="Path_9442" data-name="Path 9442" d="M0,0H18V18H0Z" fill="none" />
                <path id="Path_9443" data-name="Path 9443"
                  d="M4,18.25a6.571,6.571,0,1,1,13.143,0H15.5a4.929,4.929,0,0,0-9.857,0Zm6.571-7.393A4.929,4.929,0,1,1,15.5,5.929,4.927,4.927,0,0,1,10.571,10.857Zm0-1.643A3.286,3.286,0,1,0,7.286,5.929,3.285,3.285,0,0,0,10.571,9.214Z"
                  transform="translate(-1.571 -1)" />
              </svg>
            </span>
            Users
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="client_permission" class="nav-item">
          <router-link :class="
              $route.name == 'all_clients' ||
              $route.name == 'add_client' ||
              $route.name == 'edit_client'||
              $route.name == 'edit_client_self_assessment'||
              $route.name == 'edit_client_ergo_eval' ||
              $route.name == 'edit_client_self_assessment_lab' ||
              $route.name == 'edit_client_ergo_eval_lab'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'all_clients' }">
            <span data-feather="shopping-cart">
              <svg id="Clients" xmlns="http://www.w3.org/2000/svg" width="17.28" height="18" viewBox="0 0 17.28 18">
                <path id="Path_118" data-name="Path 118" d="M0,0H17.28V17.28H0Z" transform="translate(0 0.72)"
                  fill="none" />
                <path id="Path_119" data-name="Path 119"
                  d="M4,19a6.857,6.857,0,1,1,13.714,0Zm7.714-5.072v3.357h3.993A5.151,5.151,0,0,0,11.714,13.928ZM10,17.286V13.928a5.151,5.151,0,0,0-3.993,3.357Zm.857-6A5.143,5.143,0,1,1,16,6.143,5.141,5.141,0,0,1,10.857,11.286Zm0-1.714A3.429,3.429,0,1,0,7.429,6.143,3.428,3.428,0,0,0,10.857,9.571Z"
                  transform="translate(-2.217 -1)" />
              </svg>
            </span>
            Clients
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="email_permission" class="nav-item">
          <router-link :class="
              $route.name == 'email_template' ||
              $route.name == 'add_new_template' ||
              $route.name =='edit_new_template'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'email_template' }" aria-current="page">
            <span data-feather="home">
              <svg id="Email_Templates" data-name="Email Templates" xmlns="http://www.w3.org/2000/svg" width="18"
                height="18" viewBox="0 0 18 18">
                <path id="Path_9444" data-name="Path 9444" d="M0,0H18V18H0Z" fill="none" />
                <path id="Path_9445" data-name="Path 9445"
                  d="M2.9,3H19.1a.846.846,0,0,1,.9.778V16.222a.846.846,0,0,1-.9.778H2.9a.846.846,0,0,1-.9-.778V3.778A.846.846,0,0,1,2.9,3ZM18.2,6.3l-7.135,5.522L3.8,6.279v9.165H18.2ZM4.26,4.556l6.8,5.182,6.7-5.182Z"
                  transform="translate(-2 -1.8)" />
              </svg>
            </span>
            Email Templates
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="notification_permission" class="nav-item">
          <router-link :class="
              $route.name == 'send_notification'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'send_notification' }">
            <span data-feather="file">
              <svg id="Send_Notification" data-name="Send Notification" xmlns="http://www.w3.org/2000/svg" width="18"
                height="18" viewBox="0 0 18 18">
                <path id="Path_9446" data-name="Path 9446" d="M0,0H18V18H0Z" fill="none" />
                <path id="Path_9447" data-name="Path 9447"
                  d="M15.5,13.25H17v1.5H2v-1.5H3.5V8a6,6,0,0,1,12,0Zm-1.5,0V8A4.5,4.5,0,0,0,5,8v5.25Zm-6.75,3h4.5v1.5H7.25Z"
                  transform="translate(-0.5 -0.5)" />
              </svg>
            </span>
            Send Notification
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="log_permission" class="nav-item">
          <router-link :class="
              $route.name == 'audit_log_viewer'
                ? 'router-link active'
                : 'router-link'
            " class="nav-link" :to="{ name: 'audit_log_viewer' }">
            <span data-feather="users">
              <svg id="Audit_Log_Viewer" data-name="Audit Log Viewer" xmlns="http://www.w3.org/2000/svg" width="18"
                height="18" viewBox="0 0 18 18">
                <path id="Path_9448" data-name="Path 9448" d="M0,0H18V18H0Z" fill="none" />
                <path id="Path_9449" data-name="Path 9449"
                  d="M9.3,3A8.253,8.253,0,0,1,17.41,9.75a8.252,8.252,0,0,1-16.229,0A8.253,8.253,0,0,1,9.3,3Zm0,12a6.753,6.753,0,0,0,6.582-5.249,6.754,6.754,0,0,0-13.165,0A6.754,6.754,0,0,0,9.3,15Zm0-1.875A3.375,3.375,0,1,1,12.67,9.75,3.375,3.375,0,0,1,9.3,13.125Zm0-1.5A1.875,1.875,0,1,0,7.42,9.75,1.875,1.875,0,0,0,9.3,11.625Z"
                  transform="translate(-0.295 -0.75)" />
              </svg>
            </span>
            Audit Log Viewer
          </router-link>
        </li>
        <li @click="manageTogglerCross" v-show="blog_permission" class="nav-item">
          <router-link :class="
              $route.name == 'blog_admin' ? 'router-link active' : 'router-link'
            " class="nav-link" target="_blank" :to="{ name: 'blog_admin', params: { id: blog_id }, }">
            <span data-feather="bar-chart-2" class="blogsideicon">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20.146 17.985">
                <g id="Group_14405" data-name="Group 14405" transform="translate(14413.288 11911.725)">
                  <path id="Path_9478" data-name="Path 9478"
                    d="M-14402.7-11902.11h-7.3a2.893,2.893,0,0,0-2.493,3.073c-.078,2.864,0,8.46,0,8.46a4.692,4.692,0,0,0,.217,1.656,1.385,1.385,0,0,0,1.63.74,3.4,3.4,0,0,0,1.271-.91c.377-.343.763-.665.763-.665h8.083s2.874.006,3.264-2.687a11.442,11.442,0,0,0,0-2.323"
                    transform="translate(0 -6.425)" fill="none" stroke="none" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <path id="Path_9479" data-name="Path 9479" d="M-14403.516-11884.706h2.191"
                    transform="translate(-5.833 -19.171)" fill="none" stroke="none" stroke-linecap="round"
                    stroke-width="1.5" />
                  <path id="Path_9480" data-name="Path 9480" d="M-14403.516-11884.706h2.191"
                    transform="translate(-5.833 -15.959)" fill="none" stroke="none" stroke-linecap="round"
                    stroke-width="1.5" />
                  <path id="Path_9481" data-name="Path 9481"
                    d="M-14374.438-11910.406l-5.595,5.568a7.418,7.418,0,0,0-1.2,1.6c-.461.882-.789,2.325-.543,2.638.229.287,1.832.01,2.816-.534a7.633,7.633,0,0,0,1.6-1.295l5.557-5.633a1.89,1.89,0,0,0-.281-2.349A1.766,1.766,0,0,0-14374.438-11910.406Z"
                    transform="translate(-22.462 -0.002)" fill="none" stroke="none" stroke-linecap="round"
                    stroke-width="1.5" />
                </g>
              </svg>
            </span>
            Blog Admin
          </router-link>
        </li>
      </ul>
    </div>
      </div>
  </nav>

</template>

<script>
  export default {
    name: "SideBar",
    data() {
      return {
        userRole: false,
        users_permission: false,
        client_permission: false,
        email_permission: false,
        notification_permission: false,
        log_permission: false,
        blog_permission: false,
        blog_id:'',
      }
    },
    beforeMount() {
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          this.blog_id = value.user.id;
          
          let allPermission = value.user.roles.all_permission;
          var role= false;
          if(allPermission.includes('role-view')){
              role = true;
          }
         
          if (role == true) {
            this.userRole = true;
          }

          let users = allPermission.includes('users-view');
          if (users == true) {
            this.users_permission = true;
          }

          let client = allPermission.includes('clients-view');
          if (client == true) {
            this.client_permission = true;
          }

          let email = allPermission.includes('email-templates-view');
          if (email == true) {
            this.email_permission = true;
          }

          let notification = allPermission.includes('send-notification-view'); 
          if (notification == true) {
            this.notification_permission = true;
          }

          let audit =  allPermission.includes('audit-log-viewer-view'); 
          if (audit == true) {
            this.log_permission = true;
          }
          this.blog_permission = allPermission.includes('blog-admin-center-view');

        })
        .catch((err) => {
          console.log(err);
        });
    },
      methods:{
        manageTogglerCross() {
        document.getElementById("navbarSupportedContent1").classList.add("collapsed");
        document.getElementById("navbarSupportedContent1").classList.remove("show");
      },
      zindexup() {
        document.getElementById("sidebarMenu").classList.add("zIndexup");
      },
      zindexupcross() {
        document.getElementById("sidebarMenu").classList.remove("zIndexup");
      }

      },
  };
</script>