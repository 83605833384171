<template>
  <!-- header start -->
  <header>
         <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
               <router-link class="navbar-brand m-0 p-0" :to="{ name: 'home' }">
               <img src="../assets/home/images/logo.svg" alt="PBErgo" class="img-fluid" />
               </router-link>
               <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                  </button> -->
               <button id="manageTogglerCross" type="button" class="navbar-toggler minus collapsed"  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation" v-on:click="checkMenu">
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               </button>
               <div @click="showHideToggler = !showHideToggler" class="collapse navbar-collapse align-self-start" id="navbarSupportedContent" v-scroll-lock="open">
                  <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0 align-items-lg-center">
                     <li @click="manageTogglerCross" class="nav-item mob-items">
                        <router-link class="nav-link" :to="{ name: 'self_assessments' }" active-class="active">
                        Self-Assessments
                        </router-link>
                     </li>
                     <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false" :class="{ active: $route.name === 'virtual_service' || $route.name === 'onsite_service' || $route.name === 'wellness_series' }">
                        Ergo Services
                        </a>
                        <ul @click="manageTogglerCross" class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                           <li>
                              <router-link class="nav-link mob-items" :to="{ name: 'virtual_service' }" active-class="active">Virtual Services</router-link>
                           </li>
                           <li>
                              <router-link class="nav-link mob-items" :to="{ name: 'onsite_service' }" active-class="active">Onsite Services</router-link>
                           </li>
                           <li>
                              <router-link class="nav-link mob-items" :to="{ name: 'wellness_series' }" active-class="active">Wellness Series</router-link>
                           </li>
                        </ul>
                     </li>
                     <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false" :class="{ active: $route.name === 'free_resources' || $route.name === 'blog' }">
                        Resources
                        </a>
                        <ul @click="manageTogglerCross" class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                           <li>
                        <router-link class="nav-link mob-items" :to="{ name: 'free_resources' }" active-class="active">Free Resources</router-link>
                        </li>
                        <li>
                        <a class="nav-link mob-items"  href="/blogs/en/blog" >Blog</a>
                        <!-- <router-link class="nav-link mob-items" :to="{ name: 'blog' }" :class="{ active: $route.name === 'blog' }">Blog</router-link> -->
                        </li>
                        </ul>
                     </li>
                     <li @click="manageTogglerCross" class="nav-item mob-items">
                        <router-link class="nav-link" :to="{ name: 'media' }" active-class="active">Media</router-link>
                     </li>
                     <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false" :class="{ active: $route.name === 'meet_the_team' || $route.name === 'our_story' }">
                        About
                        </a>
                        <ul @click="manageTogglerCross" class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                           <li>
                        <router-link class="nav-link mob-items" :to="{ name: 'meet_the_team' }" active-class="active">Meet the Team</router-link>
                        </li>
                        <li>
                        <router-link class="nav-link mob-items" :to="{ name: 'our_story' }" active-class="active">The PBE Story</router-link>
                        </li>
                        </ul>
                     </li>
                     <li class="nav-item mob-items text-center" @click="manageTogglerCross">
                        <router-link class="btn btn_primary" :to="{ name: 'learn_more' }">Learn More</router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </header>
  <!-- header end -->
</template>
<script>
  export default {
  name: "Header",
  data() {
    return {
     showHideToggler:true,
     divscrolltop: 0,
     open:false,
    };
  },
  beforeMount () {
    window.addEventListener('scroll', this.scrollvue);
  },
  methods: {
     manageTogglerCross(){
      document.getElementById("manageTogglerCross").classList.add("collapsed");
      if(document.getElementById("manageTogglerCross").getAttribute("aria-expanded") == "true"){
      document.getElementById("manageTogglerCross").setAttribute("aria-expanded" , false);
      document.getElementById("navbarSupportedContent").classList.remove("show");
      document.querySelector('body').style.overflow = "auto";
      }
     },
    logout() {
     this.$store.dispatch("auth/logout");
    },
    scrollvue() {
      let navbar = document.querySelector(".navbar");
        this.divscrolltop = window.scrollY;
         if(this.divscrolltop > "50"){
           navbar.classList.add("scroll-header");
         }else{
            navbar.classList.remove("scroll-header");
         }
    },
    checkMenu: function (event){
     if(event.currentTarget.getAttribute('aria-expanded') == "true"){
        this.open = true;
     }else{
       this.open = false;
     }
    }
  },
};
</script>