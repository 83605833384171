<template>

  <!-- Footer Start Here -->
  <footer class="footerAirbnb">
    <div class="content_bottom">
      <div class="widget widget_text">
        <div class="textwidget"><br>
          <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1490227337342 grid_section" style=" text-align:left;">
            <div class=" section_inner clearfix">
              <div class="section_inner_margin clearfix">
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                      <div class="wpb_text_column wpb_content_element ">
                        <div class="wpb_wrapper">
                          <h3 style="text-align: center;color:#fff;">BE PRESENT • BE MINDFUL • BE GREAT</h3>
                          <p></p>
                          <p style="text-align: center; color: #fff">Questions? Contact <a
                              href="mailto:ergonomics@airbnb.com" style="color: #fff">ergonomics@airbnb.com</a>!</p>
                        </div>
                      </div>
                      <div class="vc_empty_space" style="height: 32px">
                        <span class="vc_empty_space_inner">
                          <span class="empty_space_image"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_inner clearfix">
      <div class="footer_top_holder">
        <div class="footer_top">
          <div class="pbe_bottom_menu_container vc_column-inner ">
            <nav class="  ">
              <ul id="bottom_menu" class="">
                <li id="nav-menu-item-70445" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-70341 current_page_item active narrow">
                 <router-link :to="{ name: 'airbnb' }" class=" current ">
                    <i class="menu_icon blank fa"></i><span>Home</span><span class="plus"></span>
                 </router-link>
                </li>
                <li id="nav-menu-item-70463" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                  <router-link :to="{ name: 'productivity-a45' }">
                    <i class="menu_icon blank fa"></i><span>Productivity</span><span class="plus"></span>
                  </router-link>
                </li>
                <li id="nav-menu-item-71297" class="menu-item menu-item-type-post_type menu-item-object-page  narrow">
                  <router-link :to="{ name: 'equipment-guides-a45' }">
                    <i class="menu_icon blank fa"></i><span>Equipment Guides</span><span class="plus"></span>
                  </router-link>
                </li>
                <li id="nav-menu-item-71347" class="button menu-item menu-item-type-post_type menu-item-object-page  narrow" v-show="show_self_assessment_block">
                  <router-link :to="{ name: 'get-an-assessment-a45' }">
                    <i class="menu_icon blank fa"></i><span>Get an Assessment</span><span class="plus"></span>
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="text-center">

            <div class="container_inner 1">
              <div id="text-13" class="widget widget_text">
                <div class="textwidget text-center">
                  <p>
                    <router-link :to="{ name: 'privacy-policy' }">Privacy Policy </router-link> | <router-link :to="{ name: 'terms-of-serivce' }"> Terms of Service </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="google_translate_element" ></div>
        </div>
      </div>
    </div>
<a id="back_to_to" href="#" class="off" v-bind:class="{dblock: scrollPosition > 1000}">
			<img src="../assets/images/airbnb/bottom.png">
</a>
    <cookie-law theme="dark-lime" v-if="$route.meta.is_header != 'admin'">
      <div slot-scope="props">
        <h4>Your Privacy</h4>
        <p>We use cookies to improve your experience on our side.</p>
        <div slot="message">
          To find out more, read out our
          <router-link to="privacy_policy">Privacy Policy</router-link> or our
          <router-link to="terms_of_service">Terms Of Service</router-link>
        </div>
        <button class="btn btn_primary" @click="props.accept">Ok</button>
      </div>
    </cookie-law>
  </footer>
  <!-- Footer end Here -->
</template>

<script>
  import CookieLaw from "vue-cookie-law";

  export default {
    name: "FooterAirbnb",
    el: '#back_to_to',
    components: { CookieLaw },
    data() {
      return {
        company_site_footer: null,
        scrollPosition: null,
        show_self_assessment_block: true,
      };
    },
       methods: {
    updateScroll() {
    this.scrollPosition = window.scrollY
    }
  },
    mounted() {
      let company_data = JSON.parse(localStorage.getItem("companyInformation"));
      // let data = this.$store.getters["auth/authUser"];
      if (company_data == null) {
        company_data = [];
      }
      if (company_data.length == 0 || company_data.length === 'undefined') {
        console.log('no company values footer')
      } else {
        this.company_site_footer = company_data.site_footer;
      }
       window.addEventListener('scroll', this.updateScroll);

       // For Hide Show Self-Assessment button
        if(company_data.site_menu_details.TakeASelfAssessment.status == 0){
          this.show_self_assessment_block = false;
        }
    },
 
  };
</script>

	